<template>
  <div>
    <f-default-header :title="$t('pages.mkt.index.title')" :subtitle="$t('pages.mkt.support.subtitle')" />

    <f-list />
  </div>
</template>

<script>
import FList from "../../../components/mkt/contact/List.vue";

export default {
  components: {
    FList,
  },
  async created() {
    this.list();
  },
  methods: {
    create() {
      // this.$router.push({
      // 	name: 'order-create'
      // })
    },
    async list() {
      this.$store.dispatch("system/loading", "data");
      await this.$store.dispatch("mkt/contact_list");
      this.$store.dispatch("system/loading", "done");
    },
  },
};
</script>

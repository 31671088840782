<template>
  <f-card>
    <v-data-table :headers="headers" :items="items" @click:row="edit" />
  </f-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t('pages.mkt.support.list.name'),
          align: "start",
          sortable: true,
          value: "nome",
        },
        {
          text: this.$t('pages.mkt.support.list.email'),
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: this.$t('pages.mkt.support.list.createdAt'),
          value: "created_at",
          align: "end",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.mkt.list;
    },
    loading() {
      return !this.$store.state.system.loading.done;
    },
  },
  methods: {
    edit(contact) {
      console.log(contact);
      this.$router.push({
        name: "mkt-contact-view",
        params: {
          id: contact.id,
        },
      });
    },
  },
};
</script>
